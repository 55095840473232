var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.appId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "appId", _vm._n($$v))
                  },
                  expression: "formData.appId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "供应商来源:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.formData.scType,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "scType", $$v)
                    },
                    expression: "formData.scType"
                  }
                },
                _vm._l(_vm.sctypeOptions, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "供应商商品编码:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.code,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "code", $$v)
                  },
                  expression: "formData.code"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品logo:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.logo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "logo", $$v)
                  },
                  expression: "formData.logo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "面值:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.facePrice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "facePrice", _vm._n($$v))
                  },
                  expression: "formData.facePrice"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "建议零售价:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.salePrice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "salePrice", _vm._n($$v))
                  },
                  expression: "formData.salePrice"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "供应商给到的成本价:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.scCostPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "scCostPrice", _vm._n($$v))
                  },
                  expression: "formData.scCostPrice"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户成本价:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.costPrice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "costPrice", _vm._n($$v))
                  },
                  expression: "formData.costPrice"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货号ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.artNoId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "artNoId", _vm._n($$v))
                  },
                  expression: "formData.artNoId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "说明:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.instruction,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "instruction", $$v)
                  },
                  expression: "formData.instruction"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "使用须知:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.useNotice,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "useNotice", $$v)
                  },
                  expression: "formData.useNotice"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品状态:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                _vm._l(_vm.skus_statusOptions, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }